@use "sass:math";

@mixin fontSize($size, $sizeParent: 16){
  font-size: math.div($size, $sizeParent) * 1rem;
}

@mixin centerX {
  position:absolute;
  left: 50%;
  transform: translate(-50%);
}
@mixin centerY {
  position:absolute;
  top: 50%;
  transform: translate(0,-50%);
}
@mixin centerXY {
  position:absolute;
  top:50%;
  left: 50%;
  transform: translate(-50%,-50%);
}

@mixin fixedCover($top: 0, $bottom: 0, $left: 0, $right: 0) {
  position: fixed;
  top: $top;
  bottom: $bottom;
  left: $left;
  right: $right;
}