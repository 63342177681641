h1, .h1-style {
    @include fontSize(50);
    line-height: 60px;
}

h2, .h2-style {
    @include fontSize(50);
    line-height: 60px;
    &.large {
        @include fontSize(50);
        line-height: 60px;
    }
}

h3, .h3-style {
    @include fontSize(28);
    line-height: 40px;
    &.small {
        @include fontSize(18);
        line-height: 24px;
    }
    &.large {
        @include fontSize(50);
        line-height: 60px;
    }
}

p {
    &.small {
        @include fontSize(16);
        line-height: 21px;
    }
    &.medium {
        @include fontSize(18);
        line-height: 24px;
    }
    &.extra-large {
        @include fontSize(36);
        line-height: 46px;
    }
}

ul.text {
    &.medium {
        @include fontSize(18);
        line-height: 24px;
    }
}

.see-more {
    @include fontSize(22);
}

header {
    height: calc($heightMenu1200 + env(safe-area-inset-bottom, 0));
    .content-lien-home {
        .logo-text {
            width: 190px;
        }
    }
}

footer {
    #section-textes-defilants {
        @include fontSize(60);
        line-height: 90px;
        height: 175px;
        .content-texte-defilant {
            &.right {
                top: 85px;
            }
        }
    }
    #section-contact {
        margin-bottom: 50px;
    }
    #section-adresses {
        address {
            margin-bottom: 50px;
        }
    }
    #section-deezer {
        text-align: right;
        a {
            width: 275px;
            img {
                width: 130px;
                &.vinyle {
                    width: 140px;
                }
            }
        }
    }
}

.teaser {
    .img-teaser {
        height: 400px;
    }
    .title-teaser {
        margin-top: 20px;
    }
}

#section-logos-references {
    max-width: 860px;
    a {
        flex: 0 0 calc(100% / 6);
    }
}

#section-textes-scrolling {
    &.enhanced {
        .content-textes-scrolling {
            height: calc(100vh - $heightMenu1200);
        }
        &.nb-2-elements {
            height: calc((100vh - $heightMenu1200) * 2);
        }
        &.nb-4-elements {
            height: calc((100vh - $heightMenu1200) * 4);
        }
        &.nb-5-elements {
            height: calc((100vh - $heightMenu1200) * 5);
        }
        &.nb-6-elements {
            height: calc((100vh - $heightMenu1200) * 6);
        }
    }
}

#page-accueil {
    #section-header {
        .main-img {
            height: calc(100vh - $heightMenu1200 - 40px); // Hauteur menu - margin-top - margin-bottom
        }
    }
    #section-intro {
        h1 {
            @include fontSize(36);
            line-height: 46px;
        }
    }
    #section-references {
        #section-logos-references {
            margin: 150px auto 50px;
        }
    }
}

#page-apropos {
    #section-header {
        .section-video {
            height: calc($heightSectionVideoAPropos - $heightMenu1200);
            .content-text {
                margin-top: calc(($heightSectionVideoAPropos / 3 * 2) / 3 * 2);
                p {
                    @include fontSize(30);
                    line-height: 40px;
                }
            }
        }
    }
    #section-textes-scrolling {
        .content-textes-scrolling {
            .content-texte-scrolling {
                margin-top: 80px;
            }
        }
    }
    #section-agence {
        .content-image {
            img {
                height: 225px;
                width: 225px;
            }
        }
    }
}

#page-etudesdecas {
    #section-etudesdecas {
        margin-top: -60px;
        .teaser {
            margin-top: 60px;
        }
    }
}

#page-references {
    #section-logos-references {
        margin-bottom: 150px;
        margin-left: auto;
        margin-right: auto;
    }
    #section-etudesdecas {
        .teaser {
            margin-top: 60px;
        }
    }
}

#page-expertises {
    .section-atouts {
        .content-image {
            .content-atouts {
                .un-atout {
                    @include fontSize(21);
                    line-height: 36px;
                }
            }
        }
    }
    #section-agence {
        .content-image {
            img {
                height: 225px;
                width: 225px;
            }
        }
    }
}

#page-contact {
    #section-contact-adresses {
        .content-adresses {
            padding: 50px 50px 20px;
        }
    }
}

#page-exemple-etudesdecas {
    #section-header {
        .content-image {
            img {
                height: calc(100vh - 285px);
            }
        }
    }
    #section-intro {
        .first-part {
            @include fontSize(36);
            line-height: 46px;
        }
    }
}