.see-more {
    &:after {
        transition: background-position 0.2s ease-in, width 0.2s ease-in;
    }
    &:hover {
        &:after {
            width: 100%;
            background-position-x: 100%;
        }
    }
}

header {
    .content-menu {
        .menu-open {
            display: none;
        }
        .menu-liens {
            position: relative;
            bottom: auto;
            padding-top: 0;
            padding-bottom: 0;
            opacity: 1;
            ul {
                padding-left: 0;
                padding-right: 0;
                li {
                    display: inline-block;
                    margin-bottom: 0;
                    margin-right: 30px;
                }
            }
        }
    }
}

footer {
    padding-top: 150px;
    #section-copyright {
        text-align: left;
        .name {
            display: inline-block;
        }
        .link {
            display: inline-block;
            margin-left: 30px;
        }
    }
}

#section-header.generic {
    .logo {
        margin-bottom: 50px;
    }
}

#section-logos-references {
    a {
        flex: 0 0 calc(100% / 5);
    }
}

#page-accueil {
    #section-header {
        margin-top: 40px;
        .main-img {
            height: calc(100vh - $heightMenu - 40px); // Hauteur menu - margin-top - margin-bottom
        }
        .content-logos {
            .logo {
                width: 150px;
                margin-top: 0;
                margin-bottom: 20px;
            }
            .logo-text {
                width: 310px;
            }
        }
        &:before {
            width: 150px;
            height: 150px;
            margin-top: -65px;
            background-image: url(/images/logo-sj.webp);
        }
        &:after {
            width: 306px;
            height: 75px;
            margin-top: 65px;
        }
    }
    #section-intro {
        margin-top: 150px;
    }
    #section-textes-scrolling {
        .content-textes-scrolling {
            .content-texte-scrolling {
                .logo {
                    margin-bottom: 60px;
                    width: 190px;
                    height: 190px;
                }
            }
        }
    }
    #section-references {
        h2 {
            margin-bottom: 60px;
        }
        .une-reference {
            .content-image {
                img {
                    height: 500px;
                }
            }
            .content-text {
                padding: 30px;
            }
        }
    }
}

#page-apropos {
    #section-header {
        .section-text-logo {
            margin-top: 50px;
            margin-bottom: 100px;
        }
        .section-video {
            .content-video {
                .image-around {
                    &.closest {
                        &.top {
                            margin-top: -20px;
                        }
                        &.bottom {
                            margin-bottom: -20px;
                        }
                        &.left {
                            margin-left: -60px;
                        }
                        &.right {
                            margin-right: -60px;
                        }
                    }
                    &.furthest {
                        display: block;
                        $widthClosestImg: 200px;
                        $heightImg: 290px;
                        $widthImg: 245px;
                        height: $heightImg;
                        width: $widthImg;
                        top: 50%;
                        transform: translateY(-50%);
                        &.left {
                            left: calc(($widthClosestImg + 50px + $widthImg + 60px) * -1);
                        }
                        &.right {
                            right: calc(($widthClosestImg + 50px + $widthImg + 60px) * -1);
                        }
                    }

                }
            }
        }
    }
    #section-recap {
        padding-top: 100px;
        padding-bottom: 100px;
        .un-recap {
            padding-top: 80px;
            img {
                height: 80px;
                width: 80px;
            }
            p {
                margin-top: 30px;
            }
        }
    }
    #section-textes-scrolling {
        .content-textes-scrolling {
            .content-texte-scrolling {
                img {
                    height: 150px;
                    width: 150px;
                }
                h3 {
                    margin-top: 50px;
                }
                .see-more {
                    margin-top: 50px;
                }
            }
        }
    }
    #section-agence {
        padding-top: 150px;
        .content-image {
            img {
                height: 225px;
                width: 225px;
            }
        }
    }
    #section-etudesdecas {
        padding-top: 150px;
    }
}

#page-expertises {
    #section-header + .section-atouts {
        padding-top: 0;
    }
    .section-atouts {
        padding-top: 150px;
        padding-bottom: 150px;
        .content-image {
            img {
                height: 410px;
            }
        }
        .content-text {
            position: sticky;
            top: 150px;
            .title {
                margin-bottom: 50px;
            }
            .subtitle {
                padding-bottom: 20px;
            }
        }
        .content-image {
            padding-top: 0;
            .content-atouts {
                padding-top: 0;
                margin-top: 100px;
            }
        }
        &.noir {
            .content-image {
                order: -1;
            }
        }
    }
    #section-etudesdecas {
        padding-top: 150px;
        .teaser {
            margin-top: 60px;
        }
    }
    #section-agence {
        padding-top: 150px;
    }
}

#page-contact {
    margin-top: 90px;
    h1 {
        margin-bottom: 100px;
    }
    #section-contact-adresses {
        position: absolute;
        right: 0;
        .content-adresses {
            margin-top: -70px;
            .tel, address {
                @include fontSize(28);
                margin-bottom: 50px;
            }
        }
    }
}

#page-exemple-etudesdecas {
    #section-header {
        padding-top: 65px;
        .date {
            margin-bottom: 5px;
        }
        .content-image {
            margin-top: 40px;
            img {
                height: calc(100vh - 255px);
            }
        }
    }
    #section-intro {
        margin-top: 150px;
    }
    #section-maquettes-1 {
        margin-top: 100px;
    }
    #section-context {
        margin-top: 150px;
        margin-left: 0;
        margin-right: 0;
        padding-top: 100px;
        padding-bottom: 100px;
    }
    #section-challenge {
        margin-top: 150px;
        .content-image {
            margin-top: 100px;
        }
    }
    #section-maquettes-2 {
        margin-top: 100px;
    }
    #section-solutions {
        margin-top: 150px;
        .content-text {
            .title {
                margin-bottom: 50px;
            }
        }
        .content-solutions {
            margin-top: 100px;
            ul {
                li {
                    margin-bottom: 50px;
                }
            }
        }
    }
    #section-textes-scrolling {
        margin-top: 150px;
    }
    #section-etudesdecas {
        padding-top: 150px;
    }
}