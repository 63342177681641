// Couleurs
$noir: #000;
$noirTransparent30: rgba(0, 0, 0, 0.3);
$grisClair: #F5F5F7;
$blanc: #FFFFFF;
$rose: #DBB2B1;
$bleu: #1B4F9D;

// Dimensions
$maxWidthMedium: 850px;
$heightSectionVideoAPropos: 300vh;
$heightMenu: 70px;
$heightMenu1200: 100px;

// Bootstrap
$breakpointSm: 768px;
$breakpointMd: 992px;
$breakpointLg: 1200px;
$breakpointXl: 1640px;
$sizePaddingContainer: 20px;
$sizePaddingContainer992: 40px;
$sizeColumnsBootstrap: 15px;
$sizeColumnsBootstrap992: 15px;
$widthContainerSm: 760px;
$widthContainerMd: 980px;
$widthContainerLg: calc(1070px + $sizePaddingContainer992 * 2);
$widthContainerXl: calc(1070px + $sizePaddingContainer992 * 2);

$bold: 700;

// Z-index
$below: -1;
$above1: 15;
$menu: 40;
$menuOverlay: 50;
$menuOpen: 60;