footer {
    #section-contact {
        .title {
            max-width: $maxWidthMedium;
        }
    }
}

#section-header.generic {
    padding-top: 50px;
    .title {
        max-width: $maxWidthMedium;
    }
}

#section-logos-references {
    a {
        flex: 0 0 calc(100% / 4);
    }
}

#page-accueil {
    #section-references {
        h2 {
            max-width: $maxWidthMedium;
        }
        .une-reference {
            position: relative;
            .content-image {
                margin-right: 220px;
            }
            .content-text {
                @include centerY();
                right: 0;
                width: 330px;
                margin-top: auto;
                margin-left: auto;
            }
        }
    }
}

#page-apropos {
    #section-header {
        .section-video {
            .content-video {
                width: 410px;
                height: 630px;
                .image-around {
                    &.closest {
                        $widthImg: 200px;
                        $heightImg: 230px;
                        height: $heightImg;
                        width: $widthImg;
                        &.top {
                            top: calc(50% - $heightImg);
                        }
                        &.bottom {
                            bottom: calc(50% - $heightImg);
                        }
                        &.left {
                            left: calc($widthImg * -1);
                        }
                        &.right {
                            right: calc($widthImg * -1);
                        }
                    }
                }
            }
            .content-text {
                max-width: $maxWidthMedium;
            }
        }
    }
}

#page-apropos {
    #section-agence {
        .content-image {
            padding-bottom: 0;
        }
        .content-text {
            align-self: center;
        }
    }
}

#page-expertises {
    #section-agence {
        .content-image {
            padding-bottom: 0;
        }
        .content-text {
            align-self: center;
        }
    }
}

#page-contact {
    #section-contact-adresses {
        margin-left: $sizePaddingContainer;
        margin-right: $sizePaddingContainer;
    }
}
