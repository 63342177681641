@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@400;700&display=swap');

@mixin Comfortaa{font-family: 'Comfortaa', cursive;font-weight:400;}
@mixin ComfortaaBold{font-family: 'Comfortaa', cursive;font-weight:700;}

@font-face {
  font-family: 'chantillyregular';
  src: url('/fonts/chantilly_regular-webfont.woff2') format('woff2'),
  url('/fonts/chantilly_regular-webfont.woff') format('woff'),
  url('/fonts/chantilly_regular-webfont.svg#chantillyregular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'chantilly-demiboldregular';
  src: url('/fonts/chantilly-demibold_regular-webfont.woff2') format('woff2'),
  url('/fonts/chantilly-demibold_regular-webfont.woff') format('woff'),
  url('/fonts/chantilly-demibold_regular-webfont.svg#chantilly-demiboldregular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@mixin Chantilly{
  font-family: 'chantillyregular';
  font-weight: normal;
  font-style: normal;
}

@mixin ChantillyDemiBold{
  font-family: 'chantilly-demiboldregular';
  font-weight: normal;
  font-style: normal;
}