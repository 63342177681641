@import "variables.scss";
@import "bootstrap.scss";
@import "fonts.scss";
@import "functions.scss";

* {
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
}

body {
    @include Comfortaa;
    @include fontSize(14);
    margin: 0;
    color: $noir;
    padding-bottom: 70px;
}

h1, h2, h3, h4 {
    margin-top: 0;
    margin-bottom: 0.5rem;
    line-height: 1.2;
}

h1, .h1-style {
    @include ChantillyDemiBold;
    @include fontSize(28);
    line-height: 35px;
}

h2, .h2-style {
    @include ChantillyDemiBold;
    @include fontSize(28);
    line-height: 35px;
    &.large {
        @include ChantillyDemiBold;
        @include fontSize(36);
        line-height: 50px;
    }
}

h3, .h3-style {
    @include ChantillyDemiBold;
    @include fontSize(21);
    line-height: 30px;
    &.small {
        @include fontSize(18);
        line-height: 24px;
    }
    &.large {
        @include fontSize(28);
        line-height: 35px;
    }
    &.comfortaa {
        @include ComfortaaBold;
    }
}

p {
    margin-top: 0;
    margin-bottom: 0;
    &.small {
        @include fontSize(12);
        line-height: 16px;
    }
    &.medium {
        @include fontSize(16);
        line-height: 21px;
    }
    &.large {
        @include fontSize(18);
        line-height: 24px;
    }
    &.extra-large {
        @include fontSize(21);
        line-height: 30px;
    }
    &.chantilly {
        @include Chantilly;
    }
}

ul.text {
    padding-left: 0;
    margin-bottom: 0;
    li {
        position: relative;
        padding-left: 24px;
        list-style: none;
        &:before {
            content: '/';
            position: absolute;
            left: 0;
            top: 0;
            font-weight: $bold;
        }
    }
    &.medium {
        @include fontSize(16);
        line-height: 21px;
    }
}

a {
    text-decoration: none;
}

.click-block {
    cursor: pointer;
}

.see-more {
    @include Chantilly;
    @include fontSize(16);
    position: relative;
    color: $noir;
    padding-bottom: 12px;
    &.left:after {
        left: 0;
        transform: none;
    }
    &.white {
        color: $blanc;
        &:after {
            background: $blanc;
        }
    }
    &:after {
        content: '';
        @include centerX;
        height: 2px;
        bottom: 0;
        width: 128px;
        max-width: 100%;
        background: linear-gradient(90deg, $rose 0%, $bleu 50%, $rose 100%) 0 100%;
        background-size: 200% 100%;
    }
}

header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
    height: calc($heightMenu + env(safe-area-inset-bottom, 0));
    background-color: $blanc;
    z-index: $menu;
    .content-lien-home {
        display: inline-block;
        .logo-text {
            display: block;
            height: auto;
            width: 130px;
        }
    }
    .content-menu {
        .menu-open {
            @include ComfortaaBold;
            position: relative;
            padding-left: 30px;
            .icon-menu {
                @include centerY();
                left: 2px;
                background-color: $noir;
                width: 16px;
                height: 2px;
                &:before, &:after {
                    content: '';
                    position: absolute;
                    left: 0;
                    right: 0;
                    background-color: $noir;
                    height: 2px;
                    width: 100%;
                }
                &:before {
                    top: -4px;
                }
                &:after {
                    bottom: -4px;
                }
            }
        }
        .shadow {
            display: none;
            @include fixedCover();
            background-color: $noirTransparent30;
            opacity: 0;
            z-index: $menuOverlay;
            transition: opacity ease-in 0.2s;
        }
        .menu-liens {
            position: absolute;
            bottom: -400px;
            left: 0;
            right: 0;
            opacity: 0;
            background-color: $blanc;
            padding-top: 50px;
            padding-bottom: 50px;
            z-index: $menuOpen;
            transition: bottom ease-in 0.2s, opacity ease-in 0.2s;
            ul {
                list-style-type: none;
                margin: 0;
                padding-left: 40px;
                padding-right: 40px;
                li {
                    margin-bottom: 30px;
                    a {
                        @include Chantilly;
                        @include fontSize(18);
                        color: $noir;
                        &:before {
                            content: '/';
                            display: inline-block;
                            margin-right: 4px;
                        }
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
        &.open {
            .shadow {
                display: block;
                opacity: 1;
            }
            .menu-liens {
                bottom: 0;
                opacity: 1;
                ul {
                    li {
                        margin-left: -100%;
                        animation: leftToRight 0.5s forwards;
                        @for $i from 2 through 5 {
                            &:nth-child(#{$i}) {
                                animation-delay: #{($i - 1) * 0.1}s;
                            }
                        }
                    }
                }
            }
        }
    }
}

footer {
    padding-top: 80px;
    padding-bottom: 30px;
    #section-textes-defilants {
        @include fontSize(30);
        position: relative;
        line-height: 50px;
        height: 100px;
        margin-bottom: 80px;
        overflow: hidden;
        .content-texte-defilant {
            position: absolute;
            min-width: 100%;
            span, &:after {
                display: inline-block;
                white-space: nowrap;
                top: 0;
            }
            &.right {
                top: 50px;
                span, &:after {
                    animation-direction: reverse;
                }
            }
            span {
                position: relative;
                animation: defilementTexteRightToLeft 60s infinite linear;
            }
            &:after {
                content: attr(data-text);
                position: absolute;
                left: 0;
                animation: defilementSecondTexteRightToLeft 60s infinite linear;
            }
        }
    }
    #section-contact {
        text-align: center;
        padding-bottom: 85px;
        border-bottom: 1px solid $noir;
        margin-bottom: 30px;
        .title {
            margin-bottom: 35px;
            max-width: 160px;
            text-align: center;
            margin-left: auto;
            margin-right: auto;
        }
        a {
            @include Chantilly;
            @include fontSize(16);
            position: relative;
            color: $blanc;
            border: 1px solid $blanc;
            background-color: $noir;
            height: 52px;
            line-height: 52px;
            padding: 0 54px;
            text-decoration: none;
            display: inline-block;
            &:before {
                content: '';
                position: absolute;
                z-index: -1;
                top: -5px;
                left: -5px;
                bottom: -5px;
                right: -5px;
                background: linear-gradient(90deg, $rose 0%, $bleu 100%);
            }
        }
    }
    #section-adresses {
        .name-address {
            margin-bottom: 10px;
        }
        address {
            font-style: normal;
            line-height: 20px;
            margin-bottom: 30px;

        }
    }
    #section-liens-sociaux {
        margin-bottom: 50px;
        a {
            display: inline-block;
            width: 55px;
            .logo {
                display: block;
                height: 24px;
                width: auto;
            }
        }
    }
    #section-deezer {
        text-align: center;
        a {
            display: inline-block;
            width: 190px;
            margin-bottom: 30px;
            img {
                display: inline-block;
                height: auto;
                width: 90px;
                &.vinyle {
                    width: 95px;
                    animation: rotate 10s linear infinite;
                }
            }
        }
    }
    #section-copyright {
        text-align: center;
        .name {
            margin-bottom: 30px;
        }
        .link {
            display: block;
            color: $noir;
            text-decoration: underline;
        }
    }
}

#section-header.generic {
    position: relative;
    padding-top: 30px;
    min-height: 100vh;
    text-align: center;
    .logo {
        margin-bottom: 30px;
    }
    .title {
        max-width: 280px;
        margin-left: auto;
        margin-right: auto;
    }
    .subtitle {
        @include centerX();
        bottom: calc(40px + $heightMenu);
        font-weight: $bold;
        &:after {
            content: '';
            display: block;
            width: 2px;
            height: 50px;
            margin-left: auto;
            margin-right: auto;
            margin-top: 30px;
            background: linear-gradient(180deg, $rose 25%, $bleu 45%, $bleu 55%, $rose 75%) 0 100%;
            background-size: 100% 400%;
            animation: defilementBackground 3s cubic-bezier(0, 0.1, 1, 0.9) infinite;
        }
    }
}

#section-logos-references {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    list-style-type: none;
    padding-left: 0;
    a {
        flex: 0 0 calc(100% / 3);
        padding: 0 15px;
        img {
            width: 100%;
            height: auto;
        }
    }
}

#section-textes-scrolling {
    .content-textes-scrolling {
        .title-section {
            padding-top: 40px;
            text-align: center;
        }
        .content-texte-scrolling {
            position: relative;
            text-align: center;
        }
    }
    &.enhanced {
        .content-textes-scrolling {
            position: sticky;
            top: 0;
            height: calc(100vh - $heightMenu);
            .content-texte-scrolling {
                @include centerY();
                width: 100%;
                opacity: 0;
                transition: opacity 0.5s ease-in;
                &.on-top {
                    opacity: 1;
                    z-index: $above1;
                }
            }
        }
        &.nb-2-elements {
            height: calc((100vh - $heightMenu) * 2);
        }
        &.nb-4-elements {
            height: calc((100vh - $heightMenu) * 4);
        }
        &.nb-5-elements {
            height: calc((100vh - $heightMenu) * 5);
        }
        &.nb-6-elements {
            height: calc((100vh - $heightMenu) * 6);
        }
    }
}

#section-etudesdecas {
    max-width: 1360px;
}

.teaser {
    .img-teaser {
        display: block;
        width: 100%;
        height: 250px;
        object-fit: cover;
    }
    .title-teaser {
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .see-more {
        display: inline-block;
        margin-top: 15px;
    }
}

#page-accueil {
    #section-header {
        position: relative;
        margin-top: 20px;
        .main-img {
            display: block;
            height: calc(100vh - $heightMenu - 20px); // Hauteur menu - margin-top
            width: 100%;
            object-fit: cover;
            animation: fadeIn 0.5s ease-in forwards;
            transition: width 0.2s ease-in;
        }
        .content-logos {
            @include centerXY;
            opacity: 0;
            animation: fadeIn 1.5s ease-in forwards;
            animation-delay: .5s;
            .logo {
                display: block;
                height: auto;
                width: 100px;
                margin: 20px auto 10px;
            }
            .logo-text {
                display: block;
                height: auto;
                width: 210px;
            }
        }
    }
    #section-intro {
        margin-top: 80px;
        h1 {
            @include ComfortaaBold();
            @include fontSize(28);
            line-height: 35px;
            margin-bottom: 20px;
        }
        .see-more {
            display: block;
            width: fit-content;
            margin: 50px auto 30px;
        }
    }
    #section-textes-scrolling {
        .content-textes-scrolling {
            .content-texte-scrolling {
                .logo {
                    display: inline-block;
                    margin-left: auto;
                    margin-right: auto;
                    margin-bottom: 30px;
                    width: 130px;
                    height: 130px;
                }
                .title {
                    margin-bottom: 20px;
                }
                .see-more {
                    display: inline-block;
                    margin-top: 60px;
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }
    }
    #section-references {
        h2 {
            text-align: center;
            max-width: 270px;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 30px;
        }
        .une-reference {
            .content-image {
                position: relative;
                z-index: $below;
                margin-right: 40px;
                img {
                    display: block;
                    width: 100%;
                    height: 250px;
                    object-fit: cover;
                }
            }
            .content-text {
                background-color: $noir;
                color: $blanc;
                margin-left: 40px;
                margin-top: -60px;
                padding: 20px;
                .see-more {
                    display: inline-block;
                    margin-top: 15px;
                }
            }
        }
        #section-logos-references {
            margin-top: 80px;
            margin-bottom: 30px;
        }
        .all-references {
            display: block;
            width: fit-content;
            margin-left: auto;
            margin-right: auto;
        }
    }
}

#page-apropos {
    #section-header {
        .section-text-logo {
            margin-top: 30px;
            margin-bottom: 60px;
            text-align: center;
            .logo {
                margin-bottom: 30px;
            }
        }
        .section-video {
            position: relative;
            height: calc($heightSectionVideoAPropos - $heightMenu);
            text-align: center;
            contain: paint;
            .content-video {
                position: sticky;
                top: 0;
                height: 400px;
                width: 205px;
                margin-left: auto;
                margin-right: auto;
                .image-around {
                    position: absolute;
                    object-fit: cover; // TEMPORAIRE, IMAGES À RETAILLER
                    &.closest {
                        $heightImg: 130px;
                        $widthImg: 100px;
                        height: $heightImg;
                        width: $widthImg;
                        &.top {
                            margin-top: -10px;
                            top: calc(50% - $heightImg);
                        }
                        &.bottom {
                            margin-bottom: -10px;
                            bottom: calc(50% - $heightImg);
                        }
                        &.left {
                            margin-left: -20px;
                            left: calc($widthImg * -1);
                        }
                        &.right {
                            margin-right: -20px;
                            right: calc($widthImg * -1);
                        }
                    }
                    &.furthest {
                        display: none;
                    }
                }
                video {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
            }
            .content-text {
                position: relative;
                margin-top: calc(($heightSectionVideoAPropos / 3 * 2) / 3 * 2);
                p {
                    @include ComfortaaBold;
                    @include fontSize(22);
                    line-height: 30px;
                }
            }
        }
    }
    #section-recap {
        text-align: center;
        color: $blanc;
        background-color: $noir;
        padding-top: 50px;
        padding-bottom: 80px;
        .un-recap {
            padding-top: 40px;
            img {
                height: 60px;
                width: 60px;
            }
            p {
                max-width: 250px;
                margin: 20px auto 0;
            }
        }
    }
    #section-textes-scrolling {
        padding-top: 40px;
        .content-textes-scrolling {
            .content-texte-scrolling {
                margin-top: 40px;
                img {
                    display: block;
                    margin-left: auto;
                    margin-right: auto;
                    height: 100px;
                    width: 100px;
                }
                h3 {
                    margin-top: 30px;
                    margin-bottom: 20px;
                }
                .see-more {
                    display: inline-block;
                    margin-top: 25px;
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }
    }
    #section-solutions {
        text-align: center;
        padding-top: 80px;
        .une-solution {
            padding-top: 60px;
            padding-bottom: 20px;
            img {
                height: 100px;
                width: 100px;
            }
            h3 {
                margin-top: 30px;
                margin-bottom: 20px;
            }
            .see-more {
                display: inline-block;
                margin-top: 25px;
            }
        }
    }
    #section-agence {
        padding-top: 80px;
        .content-image {
            padding-bottom: 40px;
            text-align: center;
        }
        .content-text {
            p {
                margin-bottom: 20px;
            }
            .see-more {
                display: inline-block;
                margin-top: 20px;
            }
        }
    }
    #section-etudesdecas {
        padding-top: 80px;
        .teaser {
            margin-top: 40px;
        }
    }
}

#page-etudesdecas {
    #section-etudesdecas {
        margin-top: -40px;
        .teaser {
            margin-top: 40px;
        }
    }
}

#page-references {
    #section-logos-references {
        margin-bottom: 80px;
    }
    #section-etudesdecas {
        .teaser {
            margin-top: 40px;
        }
    }
}

#page-expertises {
    .section-atouts {
        padding-top: 50px;
        padding-bottom: 50px;
        .content-text {
            .title {
                margin-bottom: 20px;
            }
            .subtitle {
                padding-bottom: 10px;
            }
        }
        .content-image {
            padding-top: 50px;
            img {
                display: block;
                width: 100%;
                height: 200px;
                object-fit: cover;
            }
            .content-atouts {
                padding-top: 30px;
                text-transform: uppercase;
                .un-atout {
                    @include fontSize(16);
                    line-height: 27px;
                    font-weight: $bold;
                    &:not(:last-child) {
                        padding-bottom: 20px;
                    }
                }
            }
        }
        &.noir {
            background-color: $noir;
            color: $blanc;
        }
    }
    #section-agence {
        padding-top: 80px;
        .content-image {
            padding-bottom: 40px;
            text-align: center;
        }
        .content-text {
            p {
                margin-bottom: 20px;
            }
            .see-more {
                display: inline-block;
                margin-top: 20px;
            }
        }
    }
    #section-etudesdecas {
        padding-top: 80px;
        .teaser {
            margin-top: 40px;
        }
    }
}

#page-contact {
    margin-top: 50px;
    h1 {
        margin-bottom: 60px;
        span {
            @include ComfortaaBold;
            @include fontSize(18);
            display: block;
            margin-top: 20px;
        }
    }
    #section-contact-adresses {
        margin-bottom: 80px;
        .content-image {
            margin-left: $sizePaddingContainer;
            margin-right: -$sizePaddingContainer;
            img {
                display: block;
                width: 100%;
            }
        }
        .content-adresses {
            @include Chantilly;
            @include fontSize(16);
            position: relative;
            background-color: $noir;
            color: $blanc;
            margin-left: -$sizePaddingContainer;
            margin-right: $sizePaddingContainer;
            margin-top: -40px;
            padding: 40px 30px 10px 30px;
            .tel, address {
                @include ChantillyDemiBold;
                @include fontSize(21);
                line-height: 30px;
                margin-top: 10px;
                margin-bottom: 30px;
            }
            .tel {
                display: block;
                color: $blanc;
            }
        }
    }
    form {
        .field {
            margin-bottom: 30px;
            label {
                @include Chantilly;
                @include fontSize(14);
                display: inline-block;
                line-height: 21px;
                margin-bottom: 5px;
            }
            input, textarea {
                @include Comfortaa();
                @include fontSize(14);
                width: 100%;
                background-color: $grisClair;
                border: none;
                border-bottom: 1px solid $noir;
                &::placeholder {
                    color: $noirTransparent30;
                }
                &:focus {
                    outline: black 1px solid;
                }
            }
            input {
                height: 45px;
                line-height: 45px;
                padding: 0 10px;
            }
            textarea {
                padding: 10px;
                resize: vertical;
            }
        }
        #infos_comp {
            position: fixed;
            left: -100px;
        }
        .btn-submit {
            @include Chantilly;
            @include fontSize(22);
            display: block;
            background-color: $noir;
            color: $blanc;
            height: 70px;
            width: 100%;
            line-height: 70px;
            text-align: center;
            border: none;
            &:not(:disabled) {
                cursor: pointer;
            }
        }
    }
}

#page-exemple-etudesdecas {
    #section-header {
        text-align: center;
        padding-top: 45px;
        .date {
            font-weight: $bold;
        }
        .content-image {
            margin-top: 25px;
            img {
                display: block;
                width: 100%;
                height: calc(100vh - 210px);
                object-fit: cover;
            }
        }
    }
    #section-intro {
        margin-top: 80px;
        .first-part {
            @include ComfortaaBold();
            @include fontSize(28);
            line-height: 35px;
            margin-bottom: 20px;
        }
        .see-more {
            display: block;
            width: fit-content;
            margin: 50px auto 30px;
        }
    }
    #section-maquettes-1 {
        margin-top: 80px;
        img {
            display: block;
            width: 100%;
            height: auto;
            max-height: 100vh;
            object-fit: cover;
        }
    }
    #section-context {
        color: $blanc;
        background-color: $noir;
        padding: 50px $sizePaddingContainer;
        margin-top: 80px;
        margin-left: calc($sizePaddingContainer * -1);
        margin-right: calc($sizePaddingContainer * -1);
        .content-text {
            max-width: $maxWidthMedium;
            margin-left: auto;
            margin-right: auto;
            .title {
                margin-bottom: 20px;
            }
        }
    }
    #section-challenge {
        margin-top: 80px;
        .content-text {
            max-width: $maxWidthMedium;
            margin-left: auto;
            margin-right: auto;
            .title {
                margin-bottom: 20px;
            }
        }
        .content-image {
            margin-top: 80px;
            img {
                display: block;
                width: 100%;
                height: auto;
                max-height: 400px;
                aspect-ratio: 1/1;
                object-fit: cover;
            }
        }
    }
    #section-maquettes-2 {
        margin-top: 80px;
        img {
            display: block;
            width: 100%;
            height: auto;
        }
    }
    #section-solutions {
        margin-top: 80px;
        .content-text {
            text-align: center;
            max-width: $maxWidthMedium;
            margin-left: auto;
            margin-right: auto;
            .title {
                margin-bottom: 20px;
            }
        }
        .content-solutions {
            margin-top: 50px;
            max-width: 610px;
            ul {
                margin-top: 0;
                li {
                    margin-bottom: 30px;
                }
            }
        }
    }
    #section-textes-scrolling {
        margin-top: 80px;
    }
    #section-etudesdecas {
        padding-top: 80px;
        .teaser {
            margin-top: 40px;
        }
    }
}

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@keyframes defilementTexteRightToLeft {
    0% { margin-left: 0; }
    100% { margin-left: -100%; }
}

@keyframes defilementSecondTexteRightToLeft {
    0% { margin-left: 100%; }
    100% { margin-left: 0; }
}

@keyframes defilementBackground {
    0% {
        background-position: 0 100%;
    }
    100% {
        background-position: 100% 0;
    }
}

@keyframes rotate {
    0% { transform: rotateZ(0deg); }
    100% { transform: rotateZ(360deg); }
}

@keyframes leftToRight {
    0% { margin-left: -100%; }
    100% { margin-left: 0; }
}

@media (min-width: 768px) {
    @import "768.scss";
}

@media (min-width: 992px) {
    @import "992.scss";
}

@media (min-width: 1200px) {
    @import "1200.scss";
}

@media (min-width: 1600px) {
    @import "1600.scss";
}